
import { Component, Prop, Vue } from 'vue-property-decorator';
import Highcharts from 'highcharts';
import hcMore from 'highcharts/highcharts-more';
import { Chart } from 'highcharts-vue';

hcMore(Highcharts);

@Component({
  components: {
    highchart: Chart,
  },
})
export default class WindDirectionChart extends Vue {
  @Prop() public readonly windDirection;

  get direction() {
    return (this.windDirection + 180) % 360;
  }

  private directions = {
    0: 'N',
    22.5: 'NNE',
    45: 'NE',
    67.5: 'ENE',
    90: 'E',
    112.5: 'ESE',
    135: 'SE',
    157.5: 'SSE',
    180: 'S',
    202.5: 'SSW',
    225: 'SW',
    247.5: 'WSW',
    270: 'W',
    292.5: 'WNW',
    315: 'NW',
    337.5: 'NNW',
  };

  get chartOptions() {
    const ctx = this;

    const chartConfig: Highcharts.Options = {
      chart: {
        alignTicks: false,
        backgroundColor: 'rgba(0,0,0,0)',
        plotBorderWidth: 0,
        plotShadow: false,
        height: 200,
      },

      tooltip: {
        enabled: false,
      },

      title: {
        text: undefined,
      },

      pane: {
        startAngle: 0,
        endAngle: 360,
        background: [
          {
            outerRadius: '105%',
            innerRadius: '0%',
            backgroundColor: 'white',
            borderWidth: 0,
          },
        ],
      },

      yAxis: [
        {
          min: 0,
          max: 360,
          lineColor: '#000',
          tickColor: '#000',
          minorTickColor: '#000',
          offset: 0,
          lineWidth: 2,
          tickInterval: 45,
          minorTickInterval: 22.5,
          labels: {
            formatter(this: Highcharts.AxisLabelsFormatterContextObject): string {
              return ctx.directions[this.value];
            },
            distance: 20,
            style: {
              color: 'white',
            },
          },
          tickLength: 7,
          tickWidth: 3,
          minorTickLength: 5,
          endOnTick: false,
        },
      ],

      series: [
        {
          type: 'gauge',
          name: 'Wind direction',
          data: [this.direction],
          pivot: {
            radius: 0,
          },
          dataLabels: {
            formatter(this: Highcharts.PointLabelObject) {
              const point: any = this.y;
              return '<span>' + Math.round(((point + 180) % 360) * 100) / 100 + ' °</span>';
            },
            backgroundColor: 'white',
            borderColor: 'black',
            style: {
              color: 'black',
              textOutline: undefined,
            },
            y: 15,
            zIndex: 10,
          },
          color: 'white',
          dial: {
            backgroundColor: '#00425c',
            borderColor: 'blacks',
            radius: '25%',
            baseWidth: 30,
            baseLength: '-300%',
            rearLength: '300%',
          },
          label: {
            enabled: true,
          },
        },
      ],

      credits: {
        enabled: false,
      },
    };

    return chartConfig;
  }
}
